import React, { FunctionComponent } from 'react';
import {
    Wrapper,
    ContainerHeader,
    CuponHeader,
    ContainerSubtitle,
    CuponHighlited,
    Divider,
} from './style';
import { CoursesSection } from '../CoursesSection';
import { GeneralVideosSection } from '../GeneralVideosSection';

export const MainContainer: FunctionComponent<{}> = () => {
    const createCoupon = () => {
        var months = [
            'JAN',
            'FEB',
            'MAR',
            'APR',
            'MAY',
            'JUN',
            'JUL',
            'AUG',
            'SEP',
            'OCT',
            'NOV',
            'DEC',
        ];
        var date = new Date();
        var monthName = months[date.getMonth()];

        return monthName + date.getFullYear();
    };

    return (
        <Wrapper>
            <ContainerHeader>{`Cursos Premium`} &darr; </ContainerHeader>
            <CuponHeader>
                {` No te pierdas el descuento del 60% con este cupón: `}
                <CuponHighlited>{` ${createCoupon()}`}</CuponHighlited>
            </CuponHeader>
            <CoursesSection />
            <Divider />
            <ContainerHeader>
                {`Cursos Gratis Más Vistos `} &darr;{' '}
            </ContainerHeader>
            <ContainerSubtitle></ContainerSubtitle>
            <GeneralVideosSection />
        </Wrapper>
    );
};
