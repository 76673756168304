import React, { FunctionComponent } from 'react';
import { Query } from './query';
import { Grids } from './style';
import { GeneralVideosBlockComponent } from '../GeneralVideosBlock';

export const GeneralVideosSection: FunctionComponent<{}> = () => {
    return (
        <Query>
            {data => (
                <>
                    <Grids>
                        {data.allMarkdownRemark.nodes.map(node => {
                            return (
                                <GeneralVideosBlockComponent
                                    key={node.id}
                                    {...node.frontmatter}
                                />
                            );
                        })}
                    </Grids>
                </>
            )}
        </Query>
    );
};
