import React, { FunctionComponent } from 'react';

import {
    FollowUsBlockContainer,
    Inner,
    Image,
    GalleryContainer,
    ImageGalleryGrid,
    ImageGallery,
    GalleryItem,
    GalleryYoutubeItem,
} from './style';
import { Props } from './type';
import { withEffects } from '../../lib/effects';

const FollowUsBlockComponent: FunctionComponent<Props> = props => {
    let { fontSize = 'standard', graphics = [] } = props;

    const { html, effectTimeout } = props;

    graphics = graphics || [];
    fontSize = fontSize || 'standard';

    let timeout = effectTimeout || 0;

    return (
        <FollowUsBlockContainer {...props}>
            {!!html && (
                <Inner
                    dangerouslySetInnerHTML={{ __html: html }}
                    fontSize={fontSize}                    
                />
            )}
            {graphics.length > 1 && (
            <GalleryContainer>
                    <ImageGallery>
                        <ImageGalleryGrid>
                            {graphics.map((item, key) => {
                                timeout += 200;
                                if (key === 0) {
                                    return (
                                        <GalleryYoutubeItem
                                            key={key}
                                            effect="fade-slide-top"
                                            effectTimeout={timeout}
                                        >
                                            <a href={item.source}>
                                                <Image
                                                    fluid={
                                                        item.image.childImageSharp
                                                            .fluid
                                                    }
                                                />
                                            </a>
                                        </GalleryYoutubeItem>
                                    );
                                } else {
                                    return (
                                        <GalleryItem
                                            key={key}
                                            effect="fade-slide-top"
                                            effectTimeout={timeout}
                                        >
                                            <a href={item.source}>
                                                <Image
                                                    fluid={
                                                        item.image.childImageSharp
                                                            .fluid
                                                    }
                                                />
                                            </a>
                                        </GalleryItem>
                                    );
                                }
                            })}
                        </ImageGalleryGrid>
                    </ImageGallery>
                </GalleryContainer>
            )}
        </FollowUsBlockContainer>
    );
};

export const FollowUsBlock = withEffects(FollowUsBlockComponent);
