import styled from 'styled-components';

export const Card = styled.article`
    display: grid;
    background-color: #181326;
    border-radius: 9px;
    border: 1px solid #181326;
    box-shadow: 0 0 30px #020204;
    overflow: hidden;
    line-height: 1.5;
    &:hover .post-link {
        color: #9984d5;
    }
    > a img {
        display: block;
    }
    > header {
        padding: 24px;
    }
    > h2:first-child {
        margin: 0 0 0.5rem 0;
    }
    .post-title {
        font-size: 1.2rem;
        margin-bottom: 0.3rem;
    }
    .post-meta {
        font-weight: 100;
        margin-bottom: 0;
    }
    .post-link {
        color: rgba(255, 255, 255, 0.88);
        text-decoration: none;
    }
`;

export const CourseBlockTitle = styled.h2`
    font-size: 1.2rem;
    margin-bottom: 0.3rem;
`;

export const CourseBlockTitleLink = styled.a`
    color: rgba(255, 255, 255, 0.88);
    text-decoration: none;
`;
