import React from 'react';
import { Props } from './type';

import {
    EmailForm,
    EmailFormHeader
} from './style';

export const EmailSignupFormBeehive: React.FunctionComponent<Props> = ({}) => {
    return (
        <EmailForm> 
            <EmailFormHeader>
                {'Enterate de nuevos cursos y promociones!'}
            </EmailFormHeader>
            <iframe src="https://embeds.beehiiv.com/dff811b4-60d8-4436-b850-63365ef43b8d?slim=true" data-test-id="beehiiv-embed" width="100%" frameBorder="0" scrolling="no" style={{ borderRadius: '4px', border: '0px', margin: 0 }}></iframe>
        </EmailForm>
    );
};
