import React, { FunctionComponent } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { QueryProps } from '../../type';

const query = graphql`
    query GeneralVideosQuery {
        allMarkdownRemark(
            sort: { fields: frontmatter___sort, order: ASC }
            filter: { fileAbsolutePath: { regex: "/generalVideos/" } }
        ) {
            nodes {
                id
                html
                frontmatter {
                    title
                    path
                    graphics {
                        image {
                            childImageSharp {
                                fluid(
                                    maxWidth: 357
                                    maxHeight: 201
                                    quality: 80
                                ) {
                                    ...GatsbyImageSharpFluid_tracedSVG
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const Query: FunctionComponent<QueryProps> = ({ children }) => {
    return <StaticQuery query={query} render={data => children(data)} />;
};
