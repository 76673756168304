import React, { FunctionComponent } from 'react';

import {
    AboutBlockContainer,
    Inner,
    ImageSingle,
    ImageWrap,
    Image,
    FlexLeft,
    FlexRight,
    AboutContainer,
} from './style';
import { Props } from './type';
import { Copyright } from '../Copyright';
import { withEffects } from '../../lib/effects';

const AboutBlockComponent: FunctionComponent<Props> = props => {
    let { fontSize = 'standard', graphics = [] } = props;

    const { html, effectTimeout } = props;

    graphics = graphics || [];
    fontSize = fontSize || 'standard';

    let timeout = effectTimeout || 0;

    return (
        <AboutBlockContainer {...props}>
            <AboutContainer>
                <FlexLeft>
                    {graphics.length === 1 && (
                        <ImageSingle>
                            <ImageWrap>
                                <Image
                                    fluid={
                                        graphics[0].image.childImageSharp.fluid
                                    }
                                />
                                <Copyright {...graphics[0]} />
                            </ImageWrap>
                        </ImageSingle>
                    )}
                </FlexLeft>
                <FlexRight>
                    {!!html && (
                        <Inner
                            dangerouslySetInnerHTML={{ __html: html }}
                            fontSize={fontSize}
                        />
                    )}
                </FlexRight>
            </AboutContainer>
        </AboutBlockContainer>
    );
};

export const AboutBlock = withEffects(AboutBlockComponent);
