import styled from 'styled-components';

export const Wrapper = styled.div`
    padding: 32px;
    max-width: 1140px;
    margin: 0 auto;
    img {
        max-width: 100%;
    }
`;

export const ContainerHeader = styled.h2``;

export const CuponHeader = styled.h3`
    color: #fcca64;
`;

export const CuponHighlited = styled.span`
    color: #ff0000;
    background-color: #ffffff;
`;

export const ContainerSubtitle = styled.h3``;

export const Divider = styled.br``;
