import styled from 'styled-components';
import { media } from '../../style';

export const Grids = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    ${media({
        lg: 'grid-template-columns: 1fr 1fr 1fr;',
        md: 'grid-template-columns: 1fr 1fr;',
        xs: 'grid-template-columns: 1fr',
    })}
    grid-gap: 32px;
    margin-top: 32px;
    margin-left: 32px;
`;
