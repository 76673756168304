import styled from 'styled-components';
import { align } from '@bucket-of-bolts/styled-companion/build';
import Img from 'gatsby-image';
import { media, grid, cell, withTheme } from '../../style';
import { effect, withEffects } from '../../lib/effects';

export const AboutBlockContainer = withTheme(styled.div`
    margin: 2rem 1rem;
    ${media({ xs: 'margin: 2rem 0rem;' })}
    ${align('center', 'center', 'column')};
    position: relative;

    // @ts-ignore
    font-size: ${props => props.theme.font[props.fontSize]};

    // @ts-ignore
    ${props => effect(props)}
`);

export const Inner = withTheme(styled.div`
    ${media({ xs: 'max-width: 80%', sm: 'max-width: 70%' })};
    // @ts-ignore
    font-size: ${props => props.theme.font[props.fontSize]};
    ${media({ xs: 'font-size: medium' })}
    font-weight: 300;
`);

export const ImageSingle = styled.div`
    position: relative;
    ${align('center', 'center')};
    width: 100%;
`;

export const ImageWrap = styled.div`
    width: 75%;
    ${media({ xs: 'width: 100%;display: inline-grid;justify-items: center;' })}
`;

export const Image = styled(Img)`
    width: 100%;
    ${media({ xs: 'width: 60%;' })}
    height: auto;
    background-color: #f2f1ef;
    border-radius: 0 50% 50% 50%;
    border: 5px solid #fdca65;
`;

export const AboutContainer = styled.div`
    display: flex;
    ${media({ xs: 'display: block;' })}
    flex-firection: row;

    padding: 20px;
    max-width: 1500px;
    margin: 0 auto;
`;

export const FlexLeft = styled.div`
    width: 45%;
    ${media({ xs: 'width: 100%;' })}
    margin: 5px;
    padding: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
`;

export const FlexRight = styled.div`
    width: 55%;
    ${media({ xs: 'width: 100%;' })}
    margin: 5px;
    padding: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
`;
