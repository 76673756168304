import React, { FunctionComponent } from 'react';
import { Query } from './query';
import { Grids } from './style';
import { CourseBlockComponent } from '../CourseBlock';

export const CoursesSection: FunctionComponent<{}> = () => {
    return (
        <Query>
            {data => (
                <>
                    <Grids>
                        {data.allMarkdownRemark.nodes.map(node => {
                            return (
                                <CourseBlockComponent
                                    key={node.id}
                                    {...node.frontmatter}
                                />
                            );
                        })}
                    </Grids>
                </>
            )}
        </Query>
    );
};
