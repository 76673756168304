import React, { FunctionComponent } from 'react';
import { Props } from './type';
import Img from 'gatsby-image';
import {
    Card,
    GeneralVideosBlockTitle,
    GeneralVideosBlockTitleLink,
} from './style';

export const GeneralVideosBlockComponent: FunctionComponent<Props> = ({
    path,
    title,
    graphics,
}) => {
    return (
        <Card>
            <a href={path}>
                {!!graphics && (
                    <Img
                        fluid={graphics[0].image.childImageSharp.fluid}
                        alt={title + '- Featured Shot'}
                    />
                )}
            </a>
            <header>
                <GeneralVideosBlockTitle>
                    <GeneralVideosBlockTitleLink href={path}>
                        {title}
                    </GeneralVideosBlockTitleLink>
                </GeneralVideosBlockTitle>
            </header>
        </Card>
    );
};
