import styled from 'styled-components';
import { media } from '../../style';

export const EmailForm = styled.form`
    display: flex;
    flex-direction: column;

    font-family: -apple-system, Helvetica, Arial, sans-serif;
    padding: 4rem;

    ${media({
        lg: 'padding: 0;',
        md: 'padding: 3rem;',
        xs: 'padding: 2rem;',
    })}
`;

export const EmailFormHeader = styled.h2`
    margin-top: 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    ${media({
        lg: 'font-size: 1rem;',
        md: 'font-size: 1rem;',
        xs: 'font-size: 0.5rem;',
    })}
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

export const EmailBox = styled.input`
    color: #2a2a2a;
    width: 100%;
    border: none;
    padding: 1rem 1.5rem;
    ${media({
        lg: 'width: 100%;',
        md: 'width: 100%;',
        xs: 'width: 50%;',
    })}
`;

export const EmailButton = styled.button`
    padding: 1rem 1.5rem;
    display: inline-block;
      
    border: none;
    background-image: none;
    background-color: #DD0505;
    color: white;

    letter-spacing: 1px;
    transition: all 0.1s linear;
      
    &:hover {
    cursor: pointer;
    background: darken(#DD0505, 15%);
`;
