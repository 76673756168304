import React, { FunctionComponent } from 'react';
import { Props } from './type';
import Img from 'gatsby-image';
import { Card, CourseBlockTitle, CourseBlockTitleLink } from './style';

export const CourseBlockComponent: FunctionComponent<Props> = ({
    path,
    title,
    graphics,
}) => {
    return (
        <Card>
            <a href={path}>
                {!!graphics && (
                    <Img
                        fluid={graphics[0].image.childImageSharp.fluid}
                        alt={title + '- Featured Shot'}
                    />
                )}
            </a>
            <header>
                <CourseBlockTitle>
                    <CourseBlockTitleLink href={path}>
                        {title}
                    </CourseBlockTitleLink>
                </CourseBlockTitle>
            </header>
        </Card>
    );
};
